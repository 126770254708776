import React, {useState, useEffect, useContext} from "react";
import styled from "styled-components";
import DatePicker, { registerLocale } from 'react-datepicker';
import es from "date-fns/locale/es";
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select'
import {DataContext} from "../../context/ContextData"
import { Input } from 'semantic-ui-react'
registerLocale("es", es)


const ModalBody = () => {

  const [primeraMinDate, setPrimeraMinDate] = useState(null)
  const [primeraMaxDate, setPrimeraMaxDate] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [selectedSecondDate, setSelectedSecondDate] = useState(null)
  const [selectedThirdDate, setSelectedThirdDate] = useState(null)
  const [selectedFourthDate, setSelectedFourthDate] = useState(null)
  const [turnos, setTurnos] = useState(null)
  const [horariosDisponibles, setHorariosDisponibles] = useState(null)
  const [segundosHorariosDisponibles, setSegundosHorariosDisponibles] = useState(null)
  const [tercerosHorariosDisponibles, setTercerosHorariosDisponibles] = useState(null)
  const [cuartosHorariosDisponibles, setCuartosHorariosDisponibles] = useState(null)

  function cargardatos (numero) {
    fetch("https://adiosnonino.com/phpindex.php")
    .then(r => r.json())
    .then(r => {
      if (numero === 'primer') {
        setPrimerosTurnos(r, barbero)
      }
      else if (numero === 'segundo') {
        setSegundosTurnos(r, barbero)
      }
      else if (numero === 'tercero') {
        setTercerosTurnos(r, barbero)
      }
      else if (numero === 'cuarto') {
        setCuartosTurnos(r, barbero)
      }
    })
    .catch(e => console.log('failed'))
  }

  const serviciosDisponibles = [
    {value: 'Corte', label: 'Corte'},
    {value: 'Afeitado', label: 'Afeitado'},
    {value: 'Corte y Afeitado', label: 'Corte y Afeitado'}
  ]

  function setPrimerosTurnos (turnos, barbero) {
    let horariosParaFilter = []
    const fechaActual = selectedDate.toISOString().split('T')[0]
    const turnosFabri = turnos.filter(turno => (turno.barbero === barbero))
    const turnosFabriHoy = turnosFabri.filter(turno => turno.fecha === fechaActual)
    const horariosOcupados = turnosFabriHoy.length ? turnosFabriHoy.map(turno => turno.hora) : []
    if (servicio !== 'Corte y Afeitado') {
      horariosParaFilter = options.filter(hora => !horariosOcupados.includes(hora.value))
    } else {
      let disponibles = options.filter(hora => !horariosOcupados.includes(hora.value))
      if (selectedDate.getDay() == 6) {
        disponibles = disponibles.filter(date => !['17:00','17:30','18:00', '18:30'].includes(date.value))
      }
      let disponiblesAmbos = []
      disponibles.forEach(hora => {
        const selectedIndex = options.indexOf(hora)
        const siguiente = options[selectedIndex + 1]
        if (disponibles.includes(siguiente)) {
          disponiblesAmbos.push(hora)
        }
      })
      horariosParaFilter = disponiblesAmbos
    }
    if (selectedDate.getDay() == 6) {
      setHorariosDisponibles(horariosParaFilter.filter(date => !['17:00','17:30','18:00', '18:30'].includes(date.value)))
      return
    }
    setHorariosDisponibles(horariosParaFilter)
  }

  function setSegundosTurnos (turnos, barbero) {
    let horariosParaFilter = []
    const fechaActual = selectedSecondDate.toISOString().split('T')[0]
    const turnosFabri = turnos.filter(turno => (turno.barbero === barbero))
    const turnosFabriHoy = turnosFabri.filter(turno => turno.fecha === fechaActual)
    const horariosOcupados = turnosFabriHoy.length ? turnosFabriHoy.map(turno => turno.hora) : []
    if (servicio !== 'Corte y Afeitado') {
      horariosParaFilter = options.filter(hora => !horariosOcupados.includes(hora.value))
    } else {
      let disponibles = options.filter(hora => !horariosOcupados.includes(hora.value))
      if (selectedDate.getDay() == 6) {
        disponibles = disponibles.filter(date => !['17:00','17:30','18:00', '18:30'].includes(date.value))
      }
      let disponiblesAmbos = []
      disponibles.forEach(hora => {
        const selectedIndex = options.indexOf(hora)
        const siguiente = options[selectedIndex + 1]
        if (disponibles.includes(siguiente)) {
          disponiblesAmbos.push(hora)
        }
      })
      horariosParaFilter = disponiblesAmbos
    }
    if (selectedSecondDate.getDay() == 6) {
      setSegundosHorariosDisponibles(horariosParaFilter.filter(date => !['17:00','17:30','18:00', '18:30'].includes(date.value)))
      return
    }
    setSegundosHorariosDisponibles(horariosParaFilter)
  }

  function setTercerosTurnos (turnos, barbero) {
    const fechaActual = selectedThirdDate.toISOString().split('T')[0]
    const turnosFabri = turnos.filter(turno => (turno.barbero === barbero))
    const turnosFabriHoy = turnosFabri.filter(turno => turno.fecha === fechaActual)
    const horariosOcupados = turnosFabriHoy.length ? turnosFabriHoy.map(turno => turno.hora) : []
    if (servicio !== 'Corte y Afeitado') {
      setTercerosHorariosDisponibles(options.filter(hora => !horariosOcupados.includes(hora.value)))
    } else {
      const disponibles = options.filter(hora => !horariosOcupados.includes(hora.value))
      let disponiblesAmbos = []
      disponibles.forEach(hora => {
        const selectedIndex = options.indexOf(hora)
        const siguiente = options[selectedIndex + 1]
        if (disponibles.includes(siguiente)) {
          disponiblesAmbos.push(hora)
        }
      })
      setTercerosHorariosDisponibles(disponiblesAmbos)
    }
  }

  function setMaxMinDate () {
    const actualDate = selectedDate.toISOString().split('T')[0].slice(0,8)
    let miniDate = actualDate + '02'
    let actualMonth = parseInt(selectedDate.getMonth()) + 2
    let parseMonth = actualMonth.toString().length < 2 ? '0' + actualMonth.toString() : actualMonth
    let maxiDate = actualDate.slice(0,5) + parseMonth + '-01'
    setPrimeraMaxDate(new Date(maxiDate))
    setPrimeraMinDate(new Date(miniDate))
  }

  function setCuartosTurnos (turnos, barbero) {
    const fechaActual = selectedFourthDate.toISOString().split('T')[0]
    const turnosFabri = turnos.filter(turno => (turno.barbero === barbero))
    const turnosFabriHoy = turnosFabri.filter(turno => turno.fecha === fechaActual)
    const horariosOcupados = turnosFabriHoy.length ? turnosFabriHoy.map(turno => turno.hora) : []
    if (servicio !== 'Corte y Afeitado') {
      setCuartosHorariosDisponibles(options.filter(hora => !horariosOcupados.includes(hora.value)))
    } else {
      const disponibles = options.filter(hora => !horariosOcupados.includes(hora.value))
      let disponiblesAmbos = []
      disponibles.forEach(hora => {
        const selectedIndex = options.indexOf(hora)
        const siguiente = options[selectedIndex + 1]
        if (disponibles.includes(siguiente)) {
          disponiblesAmbos.push(hora)
        }
      })
      setCuartosHorariosDisponibles(disponiblesAmbos)
    }
  }

  useEffect(() => {
    if (selectedDate) {
      setPrimerTurno(selectedDate.toISOString().split('T')[0])
      cargardatos('primer')
      setMaxMinDate()
    }
  }, [selectedDate])

  useEffect(() => {
    if (selectedSecondDate) {
      setSegundoTurno(selectedSecondDate.toISOString().split('T')[0])
      cargardatos('segundo')
      setMaxMinDate()
    }
  }, [selectedSecondDate])

  useEffect(() => {
    if (selectedThirdDate) {
      setTercerTurno(selectedThirdDate.toISOString().split('T')[0])
      cargardatos('tercero')
      setMaxMinDate()
    }
  }, [selectedThirdDate])

  useEffect(() => {
    if (selectedFourthDate) {
      setCuartoTurno(selectedFourthDate.toISOString().split('T')[0])
      cargardatos('cuarto')
      setMaxMinDate()
    }
  }, [selectedFourthDate])

  const {fecha,
      setFecha,
      hora,
      setHora,
      nombre,
      setNombre,
      barbero,
      setBarbero,
      servicio,
      setServicio,
      celular,
      setCelular,
      email,
      setEmail,
      primerTurno,
      setPrimerTurno,
      segundoTurno,
      setSegundoTurno,
      tercerTurno,
      setTercerTurno,
      cuartoTurno,
      setCuartoTurno,
      primerHorario,
      setPrimerHorario,
      segundoHorario,
      setSegundoHorario,
      tercerHorario,
      setTercerHorario,
      cuartoHorario,
      setCuartoHorario,
      aclaracion,
      setAclaracion} = useContext(DataContext)

  const options = [
    {value: '10:00', label: '10:00'},
    {value: '10:30', label: '10:30'},
    {value: '11:00', label: '11:00'},
    {value: '11:30', label: '11:30'},
    {value: '12:00', label: '12:00'},
    {value: '12:30', label: '12:30'},
    {value: '13:00', label: '13:00'},
    {value: '13:30', label: '13:30'},
    {value: '14:00', label: '14:00'},
    {value: '14:30', label: '14:30'},
    {value: '15:00', label: '15:00'},
    {value: '15:30', label: '15:30'},
    {value: '16:00', label: '16:00'},
    {value: '16:30', label: '16:30'},
    {value: '17:00', label: '17:00'},
    {value: '17:30', label: '17:30'}
  ]

  const barberoptions = [
    {value: 'Fabricio', label: 'Fabricio'},
  ]

  return (
    <BodyContainer>
      <div className="columnas cold">
        <div><h2>Resumen</h2></div>
        <div>
          <h6>Servicio</h6>
          <span>{servicio ? servicio : '-'}</span>
        </div>
        <div>
          <h6>Barbero</h6>
          <span>{barbero ? barbero : '-'}</span>
        </div>
        <div>
          <h6>Turnos</h6>
          <span>{primerTurno ? '1º: ' + primerTurno : '-'}</span><span>{primerHorario ? ' - ' + primerHorario + 'h' : ''}</span>
          <br/>
          <span>{segundoTurno ? '2º: ' + segundoTurno : '-'}</span><span>{segundoHorario ? ' - ' + segundoHorario + 'h' : ''}</span>
        </div>
        <div>
          <h6>Nombre</h6>
          <span>{nombre ? nombre : '-'}</span>
        </div>
        <div>
          <h6>Telefono</h6>
          <span>{celular ? celular : '-'}</span>
        </div>
        <div>
          <h6>Email</h6>
          <span>{email ? email : '-'}</span>
        </div>
        <div>
          <h6>Aclaracion</h6>
          <span>{aclaracion ? aclaracion : '-'}</span>
        </div>
      </div>
      <div className="columnas">
        <div><h2></h2></div>
        <Select className={"sarasa"} placeholder="Barbero" options={barberoptions} onChange={val => setBarbero(val.value)}/>
        <Select className={"sarasa " + (!barbero && "invisible")} placeholder="Servicio" options={serviciosDisponibles} onChange={val => setServicio(val.value)}/>
        <span className={!servicio && "invisible"}>Primer Turno</span>
        <DatePicker
          minDate={primeraMinDate}
          maxDate={primeraMaxDate}
          locale="es"
          placeholderText="Primer turno"
          className={"sarasa sarasapicker ui focus " + (!servicio && "invisible")}
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          dateFormat='yyyy-MM-dd'
          filterDate={date => date.getDay() !== 0 && date.getDay() !== 1 && date.getDate() < 8}
        />
        <Select className={"sarasa " + (!primerTurno && "invisible")} placeholder="Horario primer turno" onChange={value => setPrimerHorario(value.value)} options={horariosDisponibles} />
        <span className={!primerHorario && "invisible"}>Segundo Turno</span>
        <DatePicker
          minDate={primeraMinDate}
          maxDate={primeraMaxDate}
          locale="es"
          placeholderText="Segundo turno"
          className={"sarasa sarasapicker ui focus " + (!primerHorario && "invisible")}
          selected={selectedSecondDate ? selectedSecondDate : selectedDate}
          onChange={date => setSelectedSecondDate(date)}
          dateFormat='yyyy-MM-dd'
          filterDate={date => date.getDay() !== 0 && date.getDay() !== 1 && date.getDate() > 15 && date.getDate() < 32}
        />
        <Select className={"sarasa " + (!segundoTurno && "invisible")} placeholder="Horario segundo turno" onChange={value => setSegundoHorario(value.value)} options={segundosHorariosDisponibles} />
        <div><div class={"ui focus input sarasa " + (!segundoHorario && "invisible")}><input placeholder="Nombre y Apellido" onChange={e => setNombre(e.target.value)} type="text"/></div></div>
        <div><div class={"ui focus input sarasa " + (!nombre && "invisible")}><input placeholder="Telefono" onChange={e => setCelular(e.target.value)} type="text"/></div></div>
        <div><div class={"ui focus input sarasa " + (!celular && "invisible")}><input placeholder="Email" onChange={e => setEmail(e.target.value)} type="email"/></div></div>
        <div><div class={"ui focus input sarasa " + (!email && "invisible")}><input placeholder="Aclaracion" onChange={e => setAclaracion(e.target.value)} type="text"/></div></div>
      </div>
    </BodyContainer>
  );
};

export default ModalBody;

const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .columnas {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .columnas h6 {
    font-weight: bold;
    font-size: 1.3rem;
  }

  .columnas h2 {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .columnas span {
    font-size: 1rem;
  }

  .sarasapicker {
    margin: .5rem 0;
  }

  .sarasa {
    padding: .6rem;
    border-radius: .3rem;
    border-color: #D3D3D3;
    box-shadow: none;
    width: 100%;
    text-align: center;
  }

  .invisible {
    visibility:hidden;
  }

  .sarasa:focus {
    outline-color: #719ECE;
  }

  .sacar {
    display: none;
  }
`;
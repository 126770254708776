import React from "react";
import styled from "styled-components";
import { useState, useContext, useEffect } from "react";
import { DataContext } from "../../../context/ContextData";
import Notifications, {notify} from 'react-notify-toast';
import ModalBodyMembresia from "../../Modal/ModalBodyMembresia"
import MembresiaQuince from "../../Modal/MembresiaQuince"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import button from "../../../images/Boton.png";
import bgImage4 from "../../../images/ffondo-4.png";
import bgMobile4 from "../../../images/fondo-mobile-4.png";

const Membresia = () => {
  const [show, setShow] = useState(false);
  const [showQuince, setShowQuince] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClose = () => {
    setShow(false);
    setFecha(undefined)
    setHora(undefined)
    setNombre(undefined)
    setBarbero(undefined)
    setServicio(undefined)
    setCelular(undefined)
    setEmail(undefined)
    setPrimerTurno(undefined)
    setSegundoTurno(undefined)
    setTercerTurno(undefined)
    setCuartoTurno(undefined)
    setPrimerHorario(undefined)
    setSegundoHorario(undefined)
    setTercerHorario(undefined)
    setCuartoHorario(undefined)
    setAclaracion(undefined)
  }

  const handleCloseQuince = () => {
    setShowQuince(false);
    setFecha(undefined)
    setHora(undefined)
    setNombre(undefined)
    setBarbero(undefined)
    setServicio(undefined)
    setCelular(undefined)
    setEmail(undefined)
    setPrimerTurno(undefined)
    setSegundoTurno(undefined)
    setPrimerHorario(undefined)
    setSegundoHorario(undefined)
    setAclaracion(undefined)
  }

  const handleShow = () => setShow(true);
  const handleShowQuince = () => setShowQuince(true);
  const handleShowConfirm = () => setShowConfirm(true);
  const handleCloseConfirm = () => setShowConfirm(false);

  const {
    fecha,
    setFecha,
    hora,
    setHora,
    nombre,
    setNombre,
    barbero,
    setBarbero,
    servicio,
    setServicio,
    celular,
    setCelular,
    email,
    setEmail,
    primerTurno,
    setPrimerTurno,
    segundoTurno,
    setSegundoTurno,
    tercerTurno,
    setTercerTurno,
    cuartoTurno,
    setCuartoTurno,
    primerHorario,
    setPrimerHorario,
    segundoHorario,
    setSegundoHorario,
    tercerHorario,
    setTercerHorario,
    cuartoHorario,
    setCuartoHorario,
    aclaracion,
    setAclaracion
  } = useContext(DataContext);

  const options = [
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30']

  function sendData (type = 'semanal') {
    if (type === 'semanal') {
      if (nombre && barbero && servicio && celular && email && primerTurno && primerHorario && segundoHorario && segundoTurno && tercerHorario && tercerTurno && cuartoHorario && cuartoTurno) {
        let primeraData = {
          fecha: primerTurno,
          nombre: nombre,
          barbero: barbero,
          hora: primerHorario,
          servicio: servicio,
          celular: celular,
          aclaracion: aclaracion ? aclaracion : ''
        }

        let segundaData = {
          fecha: segundoTurno,
          nombre: nombre,
          barbero: barbero,
          hora: segundoHorario,
          servicio: servicio,
          celular: celular,
          aclaracion: aclaracion ? aclaracion : ''
        }

        let terceraData = {
          fecha: tercerTurno,
          nombre: nombre,
          barbero: barbero,
          hora: tercerHorario,
          servicio: servicio,
          celular: celular,
          aclaracion: aclaracion ? aclaracion : ''
        }

        let cuartaData = {
          fecha: cuartoTurno,
          nombre: nombre,
          barbero: barbero,
          hora: cuartoHorario,
          servicio: servicio,
          celular: celular,
          aclaracion: aclaracion ? aclaracion : ''
        }

        const turnos = [primeraData, segundaData, terceraData, cuartaData]

        if (servicio == 'Corte y Afeitado') {
          turnos.forEach(turno => {
            const primero = options.indexOf(turno.hora)
            turnos.push({
              fecha: turno.fecha,
              nombre: turno.nombre,
              barbero: turno.barbero,
              hora: options[primero+1],
              servicio: turno.servicio,
              celular: turno.celular,
              aclaracion: turno.aclaracion ? turno.aclaracion : ''
            })
          })
        }

        turnos.forEach(turno => {
          fetch("https://adiosnonino.com/phpindex.php/?insertar=1", {
            method: 'POST',
            body: JSON.stringify(turno)
          })
          .then(e => {
            handleClose()
            handleShowConfirm()
          })
          .catch(e => {
            handleClose()
            notify.show('Ha ocurrido un error!', 'error', 6000)
          })
        })
      } else {
        notify.show('Faltan datos!', 'error', 6000)
      }
    } else {
      if (nombre && barbero && servicio && celular && email && primerTurno && primerHorario && segundoHorario && segundoTurno) {
        let primeraData = {
          fecha: primerTurno,
          nombre: nombre,
          barbero: barbero,
          hora: primerHorario,
          servicio: servicio,
          celular: celular,
          aclaracion: aclaracion ? aclaracion : ''
        }

        let segundaData = {
          fecha: segundoTurno,
          nombre: nombre,
          barbero: barbero,
          hora: segundoHorario,
          servicio: servicio,
          celular: celular,
          aclaracion: aclaracion ? aclaracion : ''
        }

        let turnos = [primeraData, segundaData]

        if (servicio == 'Corte y Afeitado') {
          turnos.forEach(turno => {
            const primero = options.indexOf(turno.hora)
            turnos.push({
              fecha: turno.fecha,
              nombre: turno.nombre,
              barbero: turno.barbero,
              hora: options[primero+1],
              servicio: turno.servicio,
              celular: turno.celular,
              aclaracion: turno.aclaracion ? turno.aclaracion : ''
            })
          })
        }

        turnos.forEach(turno => {
          fetch("https://adiosnonino.com/phpindex.php/?insertar=1", {
            method: 'POST',
            body: JSON.stringify(turno)
          })
          .then(e => {
            handleCloseQuince()
            handleShowConfirm()
          })
          .catch(e => {
            handleCloseQuince()
            notify.show('Ha ocurrido un error!', 'error', 6000)
          })
        })
      } else {
        notify.show('Faltan datos!', 'error', 6000)
      }
    }
  }

  return (
    <MembresiaContainer id="turnos-y-membresias">
      <div className="text-container">
        <h4 className="contrataNuestra">Contratá nuestra</h4>
        <h1 className="membresia">Membresía</h1>
      </div>
      <div>
        <div className="button-container">
          <img onClick={() => handleShow()} className="membresiaImg" src={button} alt="obtener" />
          <div onClick={() => handleShow()} className="texto-encima">Semanal</div>  
        </div>
        <div className="button-container">
          <img onClick={() => handleShowQuince()} className="membresiaImg" src={button} alt="obtener" />
          <div onClick={() => handleShowQuince()} className="texto-encima">Quincenal</div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title><b>Membresia Semanal</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>            
          <ModalBodyMembresia/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={() => {
            sendData()
            handleClose()
          }}>
            Reservar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showQuince} onHide={handleCloseQuince} centered>
        <Modal.Header closeButton>
          <Modal.Title><b>Membresia Quincenal</b> (Dos turnos mensuales)</Modal.Title>
        </Modal.Header>
        <Modal.Body>            
          <MembresiaQuince/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseQuince}>
            Cancelar
          </Button>
          <Button variant="success" onClick={() => {
            sendData('quince')
            handleCloseQuince()
          }}>
            Reservar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirm} onHide={handleCloseConfirm} centered>
        <Modal.Header closeButton>
          <Modal.Title><h5>¡Turno reservado con exito!</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Enviamos un e-mail con los detalles de tu membresia a tu casilla de correo.
            <br/>
            Para cancelarla por favor contactate con nosotros a traves de nuestro WhatsApp.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseConfirm}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </MembresiaContainer>
  );
};

export default Membresia;

const MembresiaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 120rem;
  max-width: 100%;
  background-image: url(${bgImage4});
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: 10% 0;

  @media (min-width: 3072px) {
    background-size: 192rem;
  }

  @media (max-width: 768px) {
    background-image: url(${bgMobile4});
    background-size: 30rem;
    overflow: hidden;
  }

  .membresiaImg {
    margin-top: 10px;
    margin-bottom: 200px;
    margin-left: 275px;

    &:hover {
      cursor: pointer;
    }
  }

  .membresia,
  .contrataNuestra {
    margin: 0 auto;
    margin-right: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contrataNuestra {
    font-family: MuseoSlab;
    font-weight: 300;
    font-size: 2rem;
    margin-right: 66rem;
    color: #ae862a;
    margin-right: -245px;

    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }

  .membresia {
    font-family: ShadedLarch;
    font-size: 6rem;
    font-weight: 300;
    color: white;
    margin-right: -350px;

    @media (max-width: 768px) {
      font-size: 5rem;
    }
  }

  .text-container {
    margin-top: 175px;
    // margin-left: 1350px;

    @media (max-width: 768px) {
      margin-right: 350px;
      margin-top: 100px;
    }
  }
  .button-container {
    height: 10rem;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (max-width: 768px) {
      margin-right: 300px;
    }
  }
  .texto-encima {
    font-family: MuseoSlab;
    color: white;
    font-size: 45px;
    position: absolute;
    top: 50px;
    left: 300px;
    
    &:hover {
      cursor: pointer;
    }
  }
`;

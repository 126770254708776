import "./App.css";
import { Link, BrowserRouter, Route, Routes } from 'react-router-dom'
import Landing from './views/Landing'
import Fabri from './views/Fabri'
import Santi from './views/Santi'
import { DataProvider } from "./context/ContextData";
import Notifications, {notify} from 'react-notify-toast';

function App (){
  return (
    <DataProvider>
      <div className="App">
        <Notifications options={{zIndex: 200}}/>
        <BrowserRouter>
            <Routes>
              <Route path="/" element={<Landing/>} />
              <Route path="/fabri" element={<Fabri/>} />
              <Route path="/santi" element={<Santi/>} />
            </Routes>
        </BrowserRouter> 
      </div>
    </DataProvider> 
  );
}

export default App;
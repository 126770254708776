import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./fonts/ShadedLarch/ShadedLarch_PERSONAL_USE.ttf";
import "./fonts/MuseoSlab/MuseoSlab/Museo_Slab_700.otf";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import 'semantic-ui-css/semantic.min.css'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

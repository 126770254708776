import React, { createContext, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({children}) => {
	const [fecha, setFecha] = useState(null)
	const [hora, setHora] = useState(null)
	const [nombre, setNombre] = useState(null)
	const [barbero, setBarbero] = useState(null)
	const [servicio, setServicio] = useState(null)
	const [celular, setCelular] = useState(null)
	const [email, setEmail] = useState(null)
	const [primerTurno, setPrimerTurno] = useState(null)
	const [segundoTurno, setSegundoTurno] = useState(null)
	const [tercerTurno, setTercerTurno] = useState(null)
	const [cuartoTurno, setCuartoTurno] = useState(null)
	const [primerHorario, setPrimerHorario] = useState(null)
	const [segundoHorario, setSegundoHorario] = useState(null)
	const [tercerHorario, setTercerHorario] = useState(null)
	const [cuartoHorario, setCuartoHorario] = useState(null)
	const [aclaracion, setAclaracion] = useState(null)

	return (
		<DataContext.Provider value={{
			fecha,
			setFecha,
			hora,
			setHora,
			nombre,
			setNombre,
			barbero,
			setBarbero,
			servicio,
			setServicio,
			celular,
			setCelular,
			email,
			setEmail,
			primerTurno,
			setPrimerTurno,
			segundoTurno,
			setSegundoTurno,
			tercerTurno,
			setTercerTurno,
			cuartoTurno,
			setCuartoTurno,
			primerHorario,
			setPrimerHorario,
			segundoHorario,
			setSegundoHorario,
			tercerHorario,
			setTercerHorario,
			cuartoHorario,
			setCuartoHorario,
			aclaracion,
			setAclaracion
		}}>
			{ children }
		</DataContext.Provider>
	)
}

import React from "react";
import styled from "styled-components";

import nosotrosImg from "../../../images/team-2.jpg";
import bgImage3 from "../../../images/fondo-3.png";
import bgMobile3 from "../../../images/fondo-mobile-3.png";

const Nosotros = () => {
  return (
    <NosContainer id="nosotros">
     <div>
        <img className="nosotrosImg" src={nosotrosImg} alt="nosotros" />
      </div>
      <div className="text-container">
        <div className="title-container">
          <h4 className="sobre">SOBRE</h4>
          <h1 className="nosotros">Nosotros</h1>
        </div>
        <div>
          <p className="nosotrosDesc">
            Nos presentamos, somos Fabri y Santi pero nos gusta ser llamados por nuestros apodos, Cabezón (Fabri) y Gordo (Santi).
            Somos dos amigos que se conocieron trabajando juntos, con varias cosas en común.
            Nos une la sinceridad, la buena música y las pocas pulgas. Tenemos la buena suerte de trabajar en un espacio divertido, envolviendo en nuestras conversaciones a nuestros amigos, a nuestros clientes. 
            Hace un año comenzaba esta historia y nos encantaría que nos sigan acompañando, para brindarles una buena atención y escucha.
            Toda la información referida a nuestros servicios queda en la web, al igual que la agenda, para una mayor practicidad a la hora de solicitar tu turno.
            ¡ Salú !
          </p>
        </div>
      </div>
    </NosContainer>
  );
};

export default Nosotros;

const NosContainer = styled.div`
  max-height: 100%
  max-width: 100%


  padding: 0;
  background-image: url(${bgImage3});
  background-repeat: no-repeat;
  background-size: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (min-width: 3072px) {
    background-size: 192rem;
  }

  @media (max-width: 768px) {
    background-image: url(${bgMobile3});
    overflow: hidden;
    flex-direction: column;
  }

  .nosotrosImg {
    padding: 50px;
    max-height: 500px;
    @media (max-width: 768px) {
      max-height: 400px;
      
    }
  }

  .sobre,
  .nosotros {
    margin: 0 auto;
  }

  .sobre {
    font-family: MuseoSlab;
    font-weight: 300;
    font-size: 1.6rem;
    color: #ae862a;
    margin-right: 150px;
    @media (max-width: 768px) {
      font-size:  1.3rem;
    }
  }

  .nosotros {
    font-family: ShadedLarch;
    font-size: 5rem;
    font-weight: 300;
    color: white;
    @media (max-width: 768px) {
      font-size:  4rem;
    }
  }

  .nosotrosDesc {
    font-family: MuseoSlab;
    color: white;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 25px;
    text-align: center;

    @media (max-width: 768px) {
      font-size:  10px;
    }
  }

  .text-container {
    @media (max-width: 768px) {
      margin-top: -20px;
    }
  }

  .title-container {
    text-align: center;
  }
`;

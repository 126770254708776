import React from "react";
import { useState, useContext, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import barber1 from "../../../images/SANTI.jpg";
import barber2 from "../../../images/fabri.jpg";
import ModalBody from "../../Modal/ModalBody"

import styled from "styled-components";
import corteImg from "../../../images/CORTE.jpg";
import afeitadoImg from "../../../images/afeitado.jpg";
import corteAfeitadoImg from "../../../images/corte-y-afeitado.jpg";
import bgImage2 from "../../../images/fondo-2.png";
import bgMobile2 from "../../../images/fondo-mobile-2.png";
import { DataContext } from "../../../context/ContextData";
import Notifications, {notify} from 'react-notify-toast';

const Servicios = () => {
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowConfirm(false);
    resetForm();
  }

  const handleCloseClean = () => {
    setShow(false);
  }

  const handleCloseConfirm = () => {
    setShowConfirm(false);
    resetForm();
  }  

  const handleShow = () => setShow(true);
  const handleShowConfirm = () => setShowConfirm(true);

  const {
    fecha,
    setFecha,
    hora,
    setHora,
    nombre,
    setNombre,
    barbero,
    setBarbero,
    servicio,
    setServicio,
    celular,
    setCelular,
    email,
    setEmail,
    aclaracion,
    setAclaracion
  } = useContext(DataContext);


  const resetForm = () => {
    setFecha(undefined);
    setHora(undefined);
    setNombre(undefined);
    setBarbero(undefined);
    setServicio(undefined);
    setCelular(undefined);
    setEmail(undefined);
    setAclaracion(undefined);
  }

  const sendEmail = async () => {
    const data = {
      email: email,
      barbero: barbero,
      servicio: servicio,
      fecha: fecha,
      horario: hora
    }
    try {
      const response = await fetch("https://adiosnonino.com/phpindex.php/?mail=1", {
        method: 'POST',
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error('Error al enviar el correo');
      }
    } catch (error) {
      console.error('Error al enviar el correo:', error);
      throw error;
    }
  }

  const options = [
    '10:00', '10:30', '11:00', '11:30', '12:00', '12:30',
    '13:00', '13:30', '14:00', '14:30', '15:00', '15:30',
    '16:00', '16:30', '17:00', '17:30', '18:00', '18:30','19:00', '19:30', '20:00', '20:30', '21:00'
  ];

    const sendData = async () => {
      if (fecha && nombre && barbero && hora && servicio && celular && email) {
        setIsSubmitting(true);
        let formData = {
          fecha: fecha,
          nombre: nombre,
          barbero: barbero,
          hora: hora,
          servicio: servicio,
          celular: celular,
          aclaracion: aclaracion ? aclaracion : ''
        }
  
        let datas = [formData];
  
        if (servicio === 'Corte y Afeitado') {
          const primero = options.indexOf(hora);
          datas.push({
            ...formData,
            hora: options[primero + 1]
          });
        }
  
        try {
          for (let data of datas) {
            const response = await fetch("https://adiosnonino.com/phpindex.php/?insertar=1", {
              method: 'POST',
              body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw new Error('Error al guardar la reserva');
            }
          }
          await sendEmail();
          handleCloseClean();
          handleShowConfirm();
          notify.show('¡Reserva exitosa!', 'success', 6000);
        } catch (error) {
          console.error('Error:', error);
          handleClose();
          notify.show('Ha ocurrido un error. Por favor, inténtalo de nuevo.', 'error', 6000);
        } finally {
          setIsSubmitting(false);
        }
      } else {
        notify.show('Por favor, completa todos los campos requeridos.', 'warning', 6000);
      }
    }

  return (
    <div>
      <ServicesContainer id="servicio">
      <div className="titles">
          <h4 className="title1">Nuestros</h4>
          <h1 className="title2">Servicios</h1>
        </div>
        <div 
          className="container"
        >
          <div className="img-container">
            <div 
              className="animation"
              onClick={() => {
                setServicio("Corte")
                handleShow()
              }}
            >
              <img src={corteImg} alt="corte"/>
              <div className="texto-encima-corte">
                Corte
              </div>
            </div>
          </div>
          <div className="img-container">
            <div className="animation" onClick={() => {
              setServicio("Afeitado")
              handleShow()
            }}>
              <img
                src={afeitadoImg}
                alt="afeitado"
              />
              <div>
                <div className="texto-encima-afeitado">
                  Afeitado
                </div>
              </div>
            </div>
          </div>
          <div className="img-container">
            <div
              className="animation"
              onClick={() => {
                setServicio("Corte y Afeitado")
                handleShow()
              }}
            >
              <div>
                <div className="texto-encima-ambos">
                  Ambos
                </div>
                <img src={corteAfeitadoImg} alt="corte-y-afeitado" />
              </div>
            </div>
          </div>
        </div>
      </ServicesContainer>
      <Modal className="hola" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Agenda tu turno</Modal.Title>
        </Modal.Header>
        <Modal.Body>            
          <ModalBody/>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="danger" onClick={handleClose} disabled={isSubmitting}>
        Cancelar
          </Button>
          <Button variant="success" onClick={sendData} disabled={isSubmitting}>
          {isSubmitting ? 'Reservando...' : 'Reservar'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirm} onHide={handleCloseConfirm} centered>
        <Modal.Header closeButton>
          <Modal.Title><h5>¡Turno reservado con éxito!</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center text-align-center">
            <h4 className="mt-0 mb-0">Horario: <h6>{hora}</h6></h4>
            <h4 className="mt-0 mb-0">Barbero: <h6>{barbero}</h6></h4>
            <h4 className="mt-0 mb-0">Servicio: <h6>{servicio}</h6></h4>
            <h4 className="mt-0 mb-0">Fecha: <h6>{fecha}</h6></h4>
          </div>
          <h5>
            Enviamos un e-mail con los detalles de tu reserva a tu casilla de correo. (Puede llegar como SPAM).
            <br/>
            Para cancelarlo por favor contactate con nosotros a traves de nuestro WhatsApp.
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseConfirm}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Servicios;

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  max-width: 100%;
  background-image: url(${bgImage2});
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: 50% 70%;

  @media (min-width: 3072px) {
    background-size: 192rem;
  }

  @media (max-width: 768px) {
    background-image: url(${bgMobile2});
    background-size: 30rem;
    overflow: hidden;
  }

  heigth: 600px;
  img {
    height: 300px;
    width: 300px;
  }
  p {
    color: white;
    font-family: MuseoSlab;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
  }

  .titles {
    margin-top: 75px;
  }

  .title1,
  .title2 {
    margin: 0 auto;
    margin-right: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    @media (max-width: 768px) {
      margin-right: 100px;
    }
  }
  .title1 {
    font-family: MuseoSlab;
    font-weight: 300;
    font-size: 1.5rem;
    margin-right: 66rem;

    @media (max-width: 768px) {
      margin-right: 200px;
    }
  }
  .title2 {
    font-family: ShadedLarch;
    font-size: 4rem;
    font-weight: 300;
    color: #ae862a;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .img-container {
    padding: 30px;
    position: relative;
    display: inline-block;
    text-align: center;

    transition-duration: 0.4s;
  }
  .img-container:hover {
    transform: scale(1.1);
    transition-duration: 0.4s;
  }

  .texto-encima-corte {
    font-family: ShadedLarch;
    color: white;
    font-size: 90px;
    position: absolute;
    top: 140px;
    left: 90px;
    transform: rotate(-10deg);
  }
  .texto-encima-afeitado {
    font-family: ShadedLarch;
    color: white;
    font-size: 75px;
    position: absolute;
    top: 130px;
    left: 70px;
    transform: rotate(-10deg);
  }
  .texto-encima-ambos {
    font-family: ShadedLarch;
    color: white;
    font-size: 70px;
    position: absolute;
    top: 140px;
    left: 95px;
    transform: rotate(-10deg);
  }

  .animation {
    cursor: pointer;
  }

  .barber {
    width: 22rem;
    height: 21rem;
  }

  .barberimg {
    width: 10px;
    height: 12px;
  }
`;

import React, { useState, useEffect, useContext, useCallback } from "react";
import styled from "styled-components";
import DatePicker, { registerLocale } from 'react-datepicker';
import es from "date-fns/locale/es";
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { DataContext } from "../../context/ContextData";
import { Input } from 'semantic-ui-react';
import moment from 'moment';
import { Spinner } from "react-bootstrap";

registerLocale("es", es);

const ModalBody = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [turnos, setTurnos] = useState([]);
  const [horariosDisponibles, setHorariosDisponibles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    fecha, setFecha,
    hora, setHora,
    nombre, setNombre,
    barbero, setBarbero,
    servicio,
    celular, setCelular,
    email, setEmail,
    aclaracion, setAclaracion
  } = useContext(DataContext);

  const options = [
    { value: '10:00', label: '10:00' },
    { value: '10:30', label: '10:30' },
    { value: '11:00', label: '11:00' },
    { value: '11:30', label: '11:30' },
    { value: '12:00', label: '12:00' },
    { value: '12:30', label: '12:30' },
    { value: '13:00', label: '13:00' },
    { value: '13:30', label: '13:30' },
    { value: '14:00', label: '14:00' },
    { value: '14:30', label: '14:30' },
    { value: '15:00', label: '15:00' },
    { value: '15:30', label: '15:30' },
    { value: '16:00', label: '16:00' },
    { value: '16:30', label: '16:30' },
    { value: '17:00', label: '17:00' },
    { value: '17:30', label: '17:30' },
    { value: '18:00', label: '18:00' },
    { value: '18:30', label: '18:30' },
    { value: '19:00', label: '19:00' },
    { value: '19:30', label: '19:30' },
    { value: '20:00', label: '20:00' },
    { value: '20:30', label: '20:30' },
  ];

  const barberoptions = [
    { value: 'Fabricio', label: 'Fabricio' },
  ];

  const fetchTurnos = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://adiosnonino.com/phpindex.php");
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setTurnos(data);
    } catch (error) {
      console.error('Failed to fetch turnos:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTurnos();
  }, [fetchTurnos]);

  useEffect(() => {
    if (selectedDate) {
      setFecha(moment(selectedDate).format('YYYY-MM-DD'));
    }
  }, [selectedDate, setFecha]);

  useEffect(() => {
    if (selectedDate && barbero) {
      actualizarHorariosDisponibles();
    }
  }, [selectedDate, barbero, turnos, servicio]);

  const actualizarHorariosDisponibles = useCallback(() => {
    const fechaActual = moment(selectedDate).format('YYYY-MM-DD');
    const diaDeLaSemana = moment(selectedDate).day();

    const turnosFiltrados = turnos.filter(turno =>
        turno.barbero === barbero && turno.fecha === fechaActual
    );

    const horariosOcupados = turnosFiltrados.map(turno => turno.hora);

    // Fechas especiales de diciembre para el año 2024
    const fechasEspeciales = ['2024-12-23', '2024-12-30', '2024-12-24', '2024-12-31'];
    const isEspecialDate = fechasEspeciales.includes(fechaActual);

    let horariosDisponiblesFiltrados = options;

    // Lógica para fechas especiales de diciembre
    if (isEspecialDate) {
        if (fechaActual === '2024-12-23' || fechaActual === '2024-12-30') {
            // Lunes 23 y 30 de 10 a 19 hs
            horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                hora.value >= '10:00' && hora.value <= '18:30'
            );

            if (servicio === 'Corte y Afeitado') {
                horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                    hora.value <= '18:00'
                );
            }
        } else if (fechaActual === '2024-12-24' || fechaActual === '2024-12-31') {
            // 24 y 31 de 10 a 17 hs
            horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                hora.value >= '10:00' && hora.value <= '16:30'
            );

            if (servicio === 'Corte y Afeitado') {
                horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                    hora.value <= '16:00'
                );
            }
        }
    } else {
        // Lógica original para otros días
        if ([2, 3, 4].includes(diaDeLaSemana)) {
            horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                hora.value >= '14:00' && hora.value <= '21:00'
            );

            if (servicio === 'Corte y Afeitado') {
                horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                    hora.value <= '20:00'
                );
            }
        }

        if ([5, 6].includes(diaDeLaSemana)) {
            horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                hora.value >= '10:00' && hora.value <= '18:30'
            );

            if (servicio === 'Corte y Afeitado') {
                horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                    hora.value <= '18:00'
                );
            }
        }
    }

    // Filtrar horarios ocupados
    horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
        !horariosOcupados.includes(hora.value)
    );

    // Lógica adicional para "Corte y Afeitado"
    if (servicio === 'Corte y Afeitado') {
        horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora => {
            const horaIndex = options.findIndex(option => option.value === hora.value);
            const siguienteHora = options[horaIndex + 1];
            return !(siguienteHora && horariosOcupados.includes(siguienteHora.value));
        });

        if (isEspecialDate) {
            if (fechaActual === '2024-12-23' || fechaActual === '2024-12-30') {
                horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                    hora.value !== '18:30'
                );
            } else if (fechaActual === '2024-12-24' || fechaActual === '2024-12-31') {
                horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                    hora.value !== '16:30'
                );
            }
        } else {
            if (diaDeLaSemana >= 2 && diaDeLaSemana <= 4) {
                horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                    hora.value !== '21:00'
                );
            } else if (diaDeLaSemana === 5 || diaDeLaSemana === 6) {
                horariosDisponiblesFiltrados = horariosDisponiblesFiltrados.filter(hora =>
                    hora.value !== '18:30'
                );
            }
        }
    }

    setHorariosDisponibles(horariosDisponiblesFiltrados);
}, [selectedDate, barbero, turnos, servicio, options]);



  if (isLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <BodyContainer>
      <div className="columnas cold">
        <div><h2>Resumen</h2></div>
        <div>
          <h6>Servicio</h6>
          <span>{servicio || '-'}</span>
        </div>
        <div>
          <h6>Barbero</h6>
          <span>{barbero || '-'}</span>
        </div>
        <div>
          <h6>Fecha</h6>
          <span>{fecha || '-'}</span>
        </div>
        <div>
          <h6>Horario</h6>
          <span>{hora || '-'}</span>
        </div>
        <div>
          <h6>Nombre</h6>
          <span>{nombre || '-'}</span>
        </div>
        <div>
          <h6>Teléfono</h6>
          <span>{celular || '-'}</span>
        </div>
        <div>
          <h6>Email</h6>
          <span>{email || '-'}</span>
        </div>
        <div>
          <h6>Aclaración</h6>
          <span className="text-break">{aclaracion || '-'}</span>
        </div>
      </div>
      <div className="columnas">
        <div><h2></h2></div>
        <Select
          className="sarasa"
          placeholder="Barbero"
          options={barberoptions}
          onChange={val => setBarbero(val.value)}
        />
        <DatePicker
          locale="es"
          placeholderText="Fecha"
          className={`sarasa sarasapicker ${!barbero ? "invisible" : ""}`}
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          dateFormat='yyyy-MM-dd'
          filterDate={(date) => {
            const day = date.getDay();
            const formattedDate = moment(date).format('YYYY-MM-DD');
    
            // Excepciones: permitir solo los lunes 23 y 30 de diciembre de 2024
            const allowedMondays = ['2024-12-23', '2024-12-30'];
    
            // Permitir todos los días excepto lunes, a menos que sean los lunes permitidos
            return day !== 1 || allowedMondays.includes(formattedDate);
        }}          
        disabled={!barbero}
        />
        <Select
          className={`sarasa ${barbero && fecha ? "" : "invisible"}`}
          placeholder="Horario"
          onChange={val => setHora(val.value)}
          options={horariosDisponibles}
        />
        <Input
          className={`sarasa ${barbero && fecha && hora ? "" : "invisible"}`}
          placeholder="Nombre y Apellido"
          onChange={e => setNombre(e.target.value)}
        />
        <Input
          className={`sarasa ${barbero && fecha && hora && nombre ? "" : "invisible"}`}
          placeholder="Teléfono"
          onChange={e => setCelular(e.target.value)}
        />
        <Input
          className={`sarasa ${barbero && fecha && hora && nombre && celular ? "" : "invisible"}`}
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
          type="email"
        />
        <Input
          className={`sarasa ${barbero && fecha && hora && nombre && celular && email ? "" : "invisible"}`}
          placeholder="Aclaración"
          onChange={e => setAclaracion(e.target.value)}
        />
      </div>
    </BodyContainer>
  );
};

export default ModalBody;

const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .columnas {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .columnas h6 {
    font-weight: bold;
    font-size: 1.3rem;
  }

  .columnas h2 {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .columnas span {
    font-size: 1rem;
  }

  .sarasapicker {
    margin: .5rem 0;
  }

  .sarasa {
    padding: .5rem;
    border-radius: .3rem;
    border-color: #D3D3D3;
    box-shadow: none;
    width: 100%;
    text-align: center;
  }

  .invisible {
    visibility: hidden;
  }

  .sarasa:focus {
    outline-color: #719ECE;
  }

  .react-datepicker__input-container input[disabled] {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
`;
import React, { useState, useEffect } from "react";

const Fabri = ({ title1, title2 }) => {
  const [turnos, setTurnos] = useState(['a'])

  function cargardatos () {
    fetch("https://adiosnonino.com/phpindex.php")
    .then(r => r.json())
    .then(r => setTurnos(r))
    .catch(e => console.log('failed'))
  }

  useEffect(() => {
    cargardatos()
  }, [])

  return (
    <div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Hora</th>
            <th scope="col">Servicio</th>
            <th scope="col">Nombre</th>
            <th scope="col">Celular</th>
          </tr>
        </thead>
        <tbody>
        {turnos.length && turnos.map(turno => (
          <tr>
            <th scope="row">{turno.hora}</th>
            <td>{turno.servicio}</td>
            <td>{turno.nombre}</td>
            <td>{turno.celular}</td>
          </tr>))}
        </tbody>
      </table>
    </div>
  );
};

export default Fabri;
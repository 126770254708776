import React, { useState } from "react";

const Santi = ({ title1, title2 }) => {
  const [turnos, setTurnos] = useState(null)

  function cargardatos () {
    fetch("https://adiosnonino.com/phpindex.php")
    .then(r => r.json())
    .then(r => setTurnos(r))
    .catch(e => console.log('failed'))
  }
  
  return (
    <div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">First</th>
            <th scope="col">Last</th>
            <th scope="col">Handle</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry</td>
            <td>the Bird</td>
            <td>@twitter</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Santi;